import type { Country } from '$lib/graphql/types.d'

export function countryNameWithArticle(country: Pick<Country, 'id' | 'name'>): string {
  if (country == null) return ''

  // Mostly sourced from https://www.grammarly.com/blog/geographical-use-the
  switch (country.id) {
    case 'US':
      return 'the United States'
    case 'BS':
      return 'the Bahamas'
    case 'GB':
      return 'the United Kingdom'
    case 'AE':
      return 'the United Arab Emirates'
    case 'PH':
      return 'the Philippines'
    case 'MV':
      return 'the Maldives'
    case 'GM':
      return 'the Gambia'
    case 'NL':
      return 'the Netherlands'
  }
  return country.name
}
